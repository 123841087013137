import cloneDeep from 'lodash.clonedeep'

export default function useStructure (structure) {
  const isFirst = element => structure.value.indexOf(element) === 0
  const isLast = element => structure.value.indexOf(element) === structure.value.length - 1

  function up (element) {
    if (isFirst(element)) {
      return
    }

    const elementIndex = structure.value.indexOf(element)
    const prevIndex = elementIndex - 1

    structure.value[elementIndex] = structure.value[prevIndex]
    structure.value[prevIndex] = element
  }

  function down (element) {
    if (isLast(element)) {
      return
    }

    const elementIndex = structure.value.indexOf(element)
    const nextIndex = elementIndex + 1

    structure.value[elementIndex] = structure.value[nextIndex]
    structure.value[nextIndex] = element
  }

  function remove (element) {
    const elementIndex = structure.value.indexOf(element)
    structure.value.splice(elementIndex, 1)
  }

  function copy (element) {
    const elementIndex = structure.value.indexOf(element)
    const copy = cloneDeep(element)
    copy.id = null
    copy.key = Date.now()
    structure.value.splice(elementIndex + 1, 0, copy)
  }

  return {
    isFirst,
    isLast,
    up,
    down,
    remove,
    copy
  }
}
